<template>
  <div>
    <div class="md-modal md-effect-11 md-show">
      <div class="md-content">
        <h3 class="bg-dark">
          Gestion des sauvegardes de messages
        </h3>
        <div class="card-body p-1">
          <div class="text-center mb-3" v-if="!is_form">
            <a
              href="#"
              @click.prevent="is_form = true"
              class="btn btn-secondary"
            >
              <i class="fa fa-plus"></i>
              Nouveau message
            </a>
          </div>

          <SavedMessageForm
            :message="savedMessage"
            v-if="is_form"
            @close-form="closeSavedMessageForm"
          />
          <SavedMessageList v-else @edit="editSavedMessage" />

          <div class="card-footer text-center" v-if="!is_form">
            <button
              role="button"
              @click.prevent="close"
              type="button"
              class="btn btn-primary"
            >
              Terminer
            </button>
          </div>
        </div>
      </div>
    </div>
    <div @click.prevent="close" class="md-overlay"></div>
  </div>
</template>

<script>
import SavedMessageList from "../../components/ticket/SavedMessageList";
import SavedMessageForm from "../../components/ticket/SavedMessageForm";
export default {
  name: "TicketSavedMessage",
  components: { SavedMessageForm, SavedMessageList },
  data() {
    return {
      is_form: false,
      savedMessage: null
    };
  },
  methods: {
    close() {
      this.$router.back();
    },
    closeSavedMessageForm() {
      this.savedMessage = null;
      this.is_form = false;
    },
    editSavedMessage(savedMessage) {
      this.savedMessage = savedMessage;
      this.is_form = true;
    }
  }
};
</script>

<style scoped></style>
