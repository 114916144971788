<template>
  <div class="card-body">
    <form @submit.prevent="handleSavedMessageForm" class="form">
      <div class="form-group">
        <label>Type de ticket spécifique ?</label>
        <BaseSelect
          :options="ticket_types"
          :idValue="true"
          showField="label"
          placeholder="Selectionez le type de ticket"
          v-model="savedMessageInput.type_tickets_id"
        />
      </div>
      <div class="form-group">
        <label for="label">Titre <span class="text-danger">*</span></label>
        <input
          class="form-control"
          placeholder="Entrez le titre du message"
          v-model="savedMessageInput.label"
          id="label"
          :class="{
            'is-invalid': $v.savedMessageInput.label.$error
          }"
          @blur="$v.savedMessageInput.label.$touch()"
        />
        <template v-if="$v.savedMessageInput.label.$error">
          <label class="small form-text text-danger f-12 text-right">
            <em v-if="!$v.savedMessageInput.label.required"
              >Veuillez entrer le titre du message
            </em>
          </label>
        </template>
      </div>
      <div class="form-group">
        <label for="message"
          >Contenu du message <span class="text-danger">*</span></label
        >
        <textarea
          class="form-control"
          placeholder="Saisissez le message"
          rows="4"
          v-model="savedMessageInput.message"
          id="message"
          :class="{
            'is-invalid': $v.savedMessageInput.message.$error
          }"
          @blur="$v.savedMessageInput.message.$touch()"
        ></textarea>
        <template v-if="$v.savedMessageInput.message.$error">
          <label class="small form-text text-danger f-12 text-right">
            <em v-if="!$v.savedMessageInput.message.required"
              >Veuillez entrer le message
            </em>
          </label>
        </template>
      </div>
      <div class="form-group row" v-if="error">
        <div class="col-lg-8 offset-lg-4">
          <ul class="list-unstyled pr-2">
            <li class="text-danger"><i class="fa fa-times"></i> {{ error }}</li>
          </ul>
        </div>
      </div>
      <hr />
      <div class="row justify-content-center align-items-center">
        <div class="col-sm-auto col-sm pr-3 pr-sm-1">
          <button
            class="btn btn-dark col-auto"
            type="button"
            @click="$emit('close-form')"
          >
            <span class="fas fa-arrow-left m-r-5" />
            Annuler
          </button>
        </div>
        <div class="col-sm-auto col-sm pr-3 pr-sm-1">
          <BaseButton
            button-class="btn-secondary"
            :disabled="$v.$invalid || loading"
            :show-loading="true"
            :loading="loading"
          >
            <span class="fas fa-save m-r-5" />
            Enregistrer
          </BaseButton>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "SavedMessageForm",
  props: {
    message: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      error: null,
      savedMessageInput: {
        id: null,
        type_tickets_id: null,
        label: null,
        message: null
      }
    };
  },
  computed: {
    ...mapState("assistance", ["ticket_types"])
  },
  created() {
    if (this.message) this.savedMessageInput = Object.assign({}, this.message);
  },
  methods: {
    handleSavedMessageForm() {
      if (!this.$v.invalid) {
        this.loading = true;
        if (this.savedMessageInput.id) {
          this.$store
            .dispatch("assistance/updateSavedMessage", this.savedMessageInput)
            .then(() => {
              this.loading = false;
              this.$emit("close-form");
            })
            .catch(err => {
              if (err.message) this.error = err.message;
              this.loading = false;
            });
        } else
          this.$store
            .dispatch("assistance/createSavedMessage", {
              ...this.savedMessageInput
            })
            .then(() => {
              this.loading = false;
              this.$emit("close-form");
            })
            .catch(err => {
              if (err.message) this.error = err.message;
              this.loading = false;
            });
      }
    }
  },
  validations: {
    savedMessageInput: {
      label: { required },
      message: { required }
    }
  }
};
</script>

<style scoped></style>
