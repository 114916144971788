<template>
  <table class="table table-hover" id="saved-message-table">
    <thead>
      <tr>
        <th class="" style="width: 30px">Type</th>
        <th class="" style="width: 40px">Label</th>
        <th style="width: 30px" class="text-center">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr
        class="border-left"
        v-for="savedMessage in savedMessages"
        :key="savedMessage.id"
      >
        <td class="align-middle">
          <span v-if="savedMessage.type_ticket">
            {{ savedMessage.type_ticket.label | truncate(18) }}
          </span>
          <span v-else>#</span>
        </td>
        <td class="align-middle">
          {{ savedMessage.label }}
          <em class="d-block f-12">{{
            savedMessage.message | truncate(40)
          }}</em>
        </td>
        <td class="align-middle text-center">
          <a
            href="#"
            @click.prevent="$emit('edit', savedMessage)"
            class="text-warning m-r-10"
          >
            <i class="fa fa-edit"></i>
          </a>
          <a
            href="#"
            @click.prevent="deleteSavedMessage(savedMessage)"
            class="text-danger"
          >
            <i class="fa fa-trash-alt"></i>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapState } from "vuex";
import Vue from "vue";

export default {
  name: "SavedMessageList",
  data() {
    return {
      savedMessageField: {
        keyword: null
      }
    };
  },
  computed: {
    ...mapState("assistance", ["savedMessages"])
  },
  mounted() {
    // eslint-disable-next-line no-undef
    (async () => {
      await Vue.loadScript(
        `${window.location.origin}/assets/plugins/data-tables/js/datatables.min.js`
      );
    })().then(() => this.initMovementDatable());
  },
  methods: {
    deleteSavedMessage(savedMessage) {
      this.$store.dispatch("assistance/deleteSavedMessage", savedMessage.id);
    },
    initMovementDatable() {
      window.$("#saved-message-table").DataTable({
        dom: "frtp"
      });
    }
  }
};
</script>

<style>
.page-item.active .page-link {
  background-color: #32849c !important;
}
</style>
